@import "../../styles/mixins/index.scss";

.collapse {
  position: relative;
  color: var(--dark);
  transition: 2s background-color;
  cursor: pointer;
  background-color: var(--begie);
  margin-bottom: 10px;
  padding: 32px;
  &_active {
    background: rgba(203, 201, 201, 0.5);
    .collapse--icon {
      transform: rotate(180deg);
      &::before {
        display: none;
      }
    }
  }
  &--title {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @include md {
      font-size: 20px;
    }
    &_wrap {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &--id {
    font-size: 36px;
  }
  &--desc {
    margin-bottom: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s, margin .3s;
    &_active {
      overflow: initial;
      opacity: 1;
      max-height: 1200px;
      margin-top: 20px;
    }
    & > ul {
      list-style-type: disc;
      padding: 24px 28px 24px 48px;
      li {
        list-style-type: initial;
        margin-bottom: 20px;
      }
    }
    & > p {
      margin-bottom: 10px;
      a {
        color: var(--white);
        text-decoration: underline;
        margin-left: 10px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &--icon {
    position: relative;
    width: 0;
    border-bottom: solid 35px var(--green);
    border-right: solid 20px transparent;
    border-left: solid 20px transparent;
    transition: transform .3s;
    &::before {
      content: "";
      position: absolute;
      top: 9px;
      left: -14px;
      width: 0;
      border-bottom: solid 22px var(--begie);
      border-right: solid 14px transparent;
      border-left: solid 14px transparent;
    }
  }
}