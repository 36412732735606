@import "../../styles/mixins/index.scss";

.game {
  min-height: 100vh;
  &__bg {
    background-image: url("../../assets/images/game/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: -1;
  }
  &__titles {
    display: flex;
    gap: 34px;
    align-items: center;
    margin: 60px 0 40px;
    flex-wrap: wrap;
    @include md {
      margin: 20px 0;
    }
    @include xs {
      gap: 10px;
    }
    h2 {
      margin-bottom: 0;
      color: var(--white);
    }
  }
  &__wrap {
    cursor: grab;
  }
  &__block {
    background: rgba(242, 230, 223, 0.7);
    padding: 30px 40px;
    h3 {
      margin-bottom: 32px;
    }
    &_wrap {
      min-width: calc(100% / 3 - 32px);
      @include lg {
        min-width: calc(100% / 2 - 20px);
      }
      @include sm {
        min-width: 100%;
      }
    }
  }
}

.footer {
  margin-top: 48px;
  color: var(--white);
}
