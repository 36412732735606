@import "../../styles/mixins/index.scss";

.modal {
  max-width: 1200px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  bottom: 10px;
  background-color: rgba(242, 230, 223, 0.7);
  padding: 34px 24px 24px 24px;
  margin: 0 auto;
  border-radius: 6px;
  animation: 0.6s topToBottom;
  max-height: 100vh;
  overflow: auto;
  &__header {
    margin-bottom: 20px;
    h2 {
      color: var(--green);
      margin-bottom: 10px;
    }
  }
  &__close-btn {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 60px;
    cursor: pointer;
    color: var(--white);
    transition: 0.3s transform;
    @include sm {
      position: fixed;
    }
    &:hover {
      transform: rotate(360deg);
    }
    &__content {
      p {
        margin-bottom: 20px;
      }
    }
  }
}

.overlay {
  background-color: #0009;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000000;
}

@keyframes topToBottom {
  0% {
    opacity: 0;
    transform: translateY(-100vh);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
