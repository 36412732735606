@import "../../styles/mixins/index.scss";

.roadmap {
  background-image: url("../../assets/images/roadmap/bg.png");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  &__titles {
    display: flex;
    gap: 34px;
    align-items: center;
    margin: 60px 0 40px;
    flex-wrap: wrap;
    @include md {
      margin: 20px 0;
    }
    @include xs {
      gap: 10px;
    }
    h2 {
      margin-bottom: 0;
      color: var(--white);
    }
  }
}

.phases {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
  @include md {
    gap: 20px;
  }
  &__block {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      background: rgba(242, 230, 223, 0.7);
      width: 100%;
      height: 100%;
      filter: blur(2px);
      border-radius: 5px;
    }
    position: relative;
    width: calc(100% / 2 - 32px);
    height: 500px;
    padding: 55px;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: center;
    @include xl {
      height: auto;
    }
    @include md {
      height: auto;
      padding: 20px;
      width: calc(100% / 2 - 20px);
    }
    @include xs {
      width: 100%;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    gap: 14px;
    position: relative;
    z-index: 100;
  }
  &__num {
    font-size: 140px;
    font-family: "Cheddar Gothic Sans";
    color: var(--white-sec);
    @include md {
      font-size: 80px;
    }
  }
  &__title {
    line-height: 1;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: var(--green);
    font-size: 60px;
    text-transform: uppercase;
    font-family: "Cheddar Gothic Sans";
    @include md {
      font-size: 40px;
    }
  }
  &__arrow {
    position: relative;
    z-index: 100;
    svg {
      @include md {
        width: 100%;
      }
    }
  }
}

.footer {
  margin-top: 48px;
  color: var(--white);
}