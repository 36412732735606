@import "../../styles/mixins/index.scss";

.header {
  padding: 5px 0;
  transition: background-color 0.3s;
  position: relative;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    transition: width 3s;
  }
  &_fixed {
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100000;
    background-color: var(--menu-bg-fixed);
    .menu a {
      font-size: 16px;
      color: var(--dark);
    }
    .header__logo {
      width: 60px;
    }
    .social a {
      color: var(--dark);
    }
    .burger__icon {
      &::before, &::after {
        background-color: var(--dark);
      }
    }
  }
  &__info {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: row-reverse;
  }
}

.menu {
  display: flex;
  gap: 45px;
  &__item {
    background-color: var(--begie);
    padding: 8px 14px;
    border-radius: 6px;
    &_home {
      background-color: rgba(255, 255, 255, 0.5);
      .menu__link {
        color: var(--white);
      }
    }
    &_active {
      border: 1px solid var(--dark);
      .menu__link {
        color: var(--green);
      }
    }
  }
  &__link {
    font-weight: 700;
    color: var(--dark);
    font-size: 18px;
    text-transform: uppercase;
    transition: 0.3s;
    &:hover {
      color: var(--green);
    }
  }
  @include xl {
    gap: 20px;
  }
  @include lg {
    flex-direction: column;
    position: absolute;
    gap: 16px;
    top: 72px;
    display: none;
    z-index: 1000;
    &__open {
      display: flex;
      width: 100%;
      left: 0;
      padding-inline-start: 20px;
      padding-inline-end: 20px;
      padding-block-start: 20px;
      padding-block-end: 20px;
      background-color: var(--menu-bg-mobile);
      height: 100vh;
      overflow: auto;
    }
  }
}

.burger {
  &__btn {
    cursor: pointer;
    display: none;
    background: none;
    @include lg {
      display: block;
      height: 24px;
      width: 30px;
    }
  }
  &__icon {
    display: block;
    background-color: var(--dark);
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
    position: absolute;

    &:before {
      @extend .burger__icon;
      content: "";
      margin-top: -8px;
    }
    &::after {
      @extend .burger__icon;
      content: "";
      margin-top: 8px;
    }
    &_home {
      &, &:before, &::after {
        background-color: var(--white);
      }
    }
    &_open {
      background: transparent;
      &::before {
        margin-top: 0px;
        transform: rotate(405deg);
      }
      &::after {
        margin-top: 0px;
        transform: rotate(-405deg);
      }
    }
  }
}

.social {
  display: flex;
  gap: 32px;
  @include lg {
    gap: 8px;
  }
  a {
    background-color: var(--begie);
    padding: 9px 8px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark);
  }
  &_home {
    a {
      background-color: rgba(255, 255, 255, 0.5);
      color: var(--white);
    }
  }
}
