@import "../../styles/mixins/index.scss";

.intro {
  background-image: url("../../assets/images/intro/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &__video {
    left: 0;
    width: 100%;
    position: fixed;
    object-fit: cover;
    height: 100%;
  }
  &__block {
    margin-top: 140px;
    text-align: center;
    position: relative;
    h3 {
      @include sm {
        font-size: 24px;
      }
    }
    @include sm {
      margin-top: 0;
      text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
      padding: 20px;
    }
  }
  &__info {
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 94px);
  }
  &__title {
    font-family: "Cheddar Gothic Sans", Arial;
    font-size: 140px;
    @include lg {
      font-size: 70px;
    }
    @include sm {
      font-size: 48px;
      margin-bottom: 12px;
    }
  }
  &__mint {
    margin: 130px 0 50px auto;
    position: relative;
    @include sm {
      margin-top: 40px;
    }
  }
}
