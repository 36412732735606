@import "../../styles/mixins/index.scss";

.badge {
  position: relative;
  display: inline-block;
  &::before {
    content: "";
    background-image: url("../../assets/images/badge.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
  }
  &__text {
    position: relative;
    z-index: 100;
    padding: 8px 30px;
    @include md {
      padding: 14px 30px 8px;
    }
  }
  &_full {
    &::before {
      background-image: url("../../assets/images/badge-full.png");
    }
    .badge__text {
      padding: 10px 15px;
      @include md {
        padding: 16px 15px 16px;
      }
    }
  }
}
