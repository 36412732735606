@import "../../styles/mixins/index.scss";

.team {
  background-color: var(--white-sec);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  &__titles {
    display: flex;
    gap: 34px;
    align-items: center;
    margin: 60px 0 40px;
    flex-wrap: wrap;
    @include xs {
      gap: 10px;
      margin: 10px 0 10px;
    }
    h2 {
      margin-bottom: 0;
    }
  }
  &__wrap {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    @include md {
      gap: 20px;
    }
  }
  &__block {
    width: calc(100% / 3 - 32px);
    @include md {
      width: calc(100% / 3 - 20px);
    }
    @include sm {
      width: calc(100% / 2 - 20px);
    }
    img {
      margin-bottom: 36px;
      @include md {
        margin-bottom: 12px;
      }
    }
    h3 {
      margin-bottom: 14px;
    }
    h4 {
      color: var(--green);
      text-transform: uppercase;
    }
    a {
      color: var(--black);
    }
  }
}
.footer {
  margin-top: 48px;
  color: var(--dark);
}
