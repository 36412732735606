@import "../../styles/mixins/index.scss";

.about {
  background-color: var(--white-sec);
  min-height: 100vh;
}

.intro {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 60px;
  margin: 70px 0;
  @include md {
    gap: 20px;
    flex-direction: column;
    margin: 10px 0;
  }
}

.story {
  display: flex;
  justify-content: space-between;
  gap: 60px;
  padding: 70px 0 100px;
  @include md {
    gap: 20px;
    flex-direction: column;
    margin: 10px 0;
    flex-direction: column-reverse;
    padding: 70px 0 20px;
  }
  &__images {
    position: relative;
    img {
      &:not(:first-child) {
        position: absolute;
        left: 0;
        @include md {
          display: none;
        }
      }
      &:nth-child(2) {
        top: 30%;
      }
      &:nth-child(3) {
        top: 54%;
        left: auto;
        right: 0;
      }
      &:nth-child(4) {
        top: 72%;
      }
    }
  }
  &__info {
    flex: 0 0 60%;
  }
}
