@import "../../styles/mixins/index.scss";

.faq {
  background-color: var(--white-sec);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  &__titles {
    display: flex;
    gap: 34px;
    align-items: center;
    margin: 60px 0 40px;
    @include lg {
      flex-wrap: wrap;
      gap: 10px;
      margin: 20px 0;
    }
    h2 {
      margin-bottom: 0;
    }
  }
}
.footer {
  margin-top: 48px;
  color: var(--dark);
}
