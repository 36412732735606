@import "../mixins/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;700;900&display=swap");
@font-face {
  font-family: "Cheddar Gothic Sans";
  src: url("../fonts/CheddarGothicSans.woff2") format("woff2"),
    url("../fonts/CheddarGothicSans.woff") format("woff"),
    url("../fonts/CheddarGothicSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --black: #000000;
  --dark: #1a1f26;
  --white: #ffffff;
  --white-sec: #fcf6f4;
  --green: #1aaa6d;
  --begie: #f2e6df;
  --gray: #414040;
  --red: #dd2e20;
  --orange: #fa6c3d;
  --violet: #6461e2;
  --menu-bg-fixed: var(--begie);
  --menu-bg-mobile: var(--begie);
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  color: var(--dark);
  font-family: "Outfit", Arial;
}

h1,
h2,
h3 {
  font-family: "Cheddar Gothic Sans";
}

h2 {
  font-size: 60px;
  font-weight: 400;
  line-height: 74px;
  margin-bottom: 56px;
  @include md {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 20px;
  }
}

h3 {
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  @include md {
    font-size: 26px;
    line-height: 32px;
  }
}

h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 18px;
  color: var(--dark);
}

p {
  margin-bottom: 20px;
}

.container {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1320px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  @include xl {
    max-width: initial;
    width: 100%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--green) !important;
  background-color: var(--black);
  padding: 10px;
  border-radius: 50%;
  height: 50px !important;
  width: 50px !important;
  opacity: 0.5;
  transition: .3s opacity;
  &::after {
    font-size: 22px !important;
  }
  @include sm {
    height: 40px !important;
    width: 40px !important;
  }
  &:hover {
    opacity: 1;
  }
}
